export const En = {
    subscription: {
        signup: {
            title: "Create Account",
            labelFirstName: "First name",
            labelLastName: "Last name",
            labelTitle: "Gender",
            optionMale: "Mr.",
            optionFemale: "Mrs.",
            labelTelephone: "Phone",
            labelEmail: 'Email',
            labelEmailConfirm: 'Repeat Email',
            labelAcceptNews: "I would like to be informed about news and offers.",
            labelAcceptTerms: "I agree to the Terms of Use.",
            labelAcceptData: "I agree to the processing of personal data in accordance with the privacy policy.",
            labelHasEmail: "I have an email",
            labelNoEmail: "I do not have an email",
            btnCreateAccountTxt: "Create an account",
            labelNoEmailText: "Attention: Without an email address you will not have access to the DoryGo app.",
            labelHasAccount: "You already have an account",
            labelHasAccountLink: "Log in",
            labelPassword: "Password",
            titleLogin: "Login",
            btnLoginTxt: "Sign-in",
            labelForgetPassword: "Forgot your password?",
            btnLoginBack: "Back",
            labelInsurance: "Insurance",
            labelIdentificationNumber: "ID number",
            errorValueNeeded: "Please fill in this field",
            errorEmail: "Invalid email",
            errorPasswordInvalid:
                "The password must be at least 8 characters long, contain at least one uppercase letter, lowercase letters, one number and one special character.",
            errorPasswordMissmatch: "Passwords do not match",
            errorPermissionRequired: "Consent needed to proceed",
            errorPhoneInvalid: "Your phone number is invalid",
            labelPassword: "Password",
            labelPasswordRepeat: "Repeat password",
            errorWrongPassword: "Invalid password or email",
            errorTooManyRequests:
                "Access to this account has been temporarily disabled due to too many failed login attempts. Please try again later or reset your password.",
            errorUnknown: "An unexpected error occurred",
            errorEmailExists: "This mail address is already in use",
            ButtonContinue: "Next",
            labelCreateAccount: "Create an account",
            Announce: "Sign-in",
            PharmacyElections: "Select Pharmacy",
            OrderOverview: "Order summary",
            Managed: "Done",
            labelBirthdate: 'Birthday',
            errorValidDate: "Select a valid date.",
        },
        order: {
            title1: "Select canton",
            title2: "Choose your pharmacy",
            title3: "Insurance Card",
            labelCanton: "Canton",
            labelPharmacy: "Pharmacy",
            labelSubmit: "Go to order summary",
            btnLoginBack: "Back",
            labelDescriptionText: "Your pharmacy needs the following information about your insurance in order to be able to bill the medication and service.",
            labelCardHolder: "Insurance Name",
            labelCardNumber: "Card number",
            labelOrderBtn: "Complete your order",
            title4: "Your order",
            title5: "Personal Information",
            title6: "Your pharmacy",
            errorInvalidCardNumber:
                "Card number invalid. The number must be 20 characters long and can contain only numbers and spaces.",
            text1: "Regular deliveries or collection",
            text2: "Intake Reminders",
            text3: "Box for on the go",
            text4: "CHF free of charge",
            text5: "CHF 21.60 per week without weekly dose prescription",
            labelInsurance: "Insurance",
            labelIdentificationNumber: "ID number",
            errorValueNeeded: "Please fill in this field",
        },
        final: {
            title: "Thank you for your order!",
            labelButton1: "Issue power of attorney",
            labelButton2: "Done",
            title2: "All that's missing now is your medication prescriptions.",
            title3: "How would you like to submit the prescriptions?",
            labelSwitch1: "In person",
            labelSwitch2: "Per Post",
            labelSwitch3: "I would like to delegate this to DoryHealth",
            surveyTitle: "How did you hear from us?",
            option1: "My pharmacy ",
            option2: "My doctor",
            option3: "Flyer I received in my mailbox",
            option4: "Online, after a search",
            option5: "Les Invisibles/ Tamara",
            option6: "Someone told me about DoryHealth",
            question: "Who?",
            error: "The input text must be at least 2 characters long"
        }
    }
}