import React, { useEffect, useState } from "react"
import { withFB } from "../../Firebase/firebasecontext"

import { ThemeProvider } from "@material-ui/core/styles"

import theme from "../theme"
import Paper from "@material-ui/core/Paper"

import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepConnector from "@material-ui/core/StepConnector"
import useSharedClasses from "../FormStyles"
import FirstStepLogin from "./FirstStepLogin"
import FirstStepSignUp from "./FirstStepSignUp"
import SecondStepChooseFarmacy from "./SecondStepChooseFarmacy"
import SecondStepOrderSummary from "./SecondStepOrderSummary"
import ThirdStep from "./ThirdStep"
import { deDE } from "../../../lang/de-DE"
import { En } from "../../../lang/en"
import { frCH } from "../../../lang/fr-CH"

const FormSubscriptionBase = (props) => {
    const steps = [["Konto erstellen", "Anmelden"], [
        "Apotheke wählen", "Bestellübersicht"
    ], "Geschafft"];

    const today = new Date()
    const someYearsAgo = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate(),
        today.getHours()
    )

    const classes = useSharedClasses()
    const [activeStep, setActiveStep] = useState(0)
    const [txt, setTxt] = useState(deDE.subscription)
    const [hasAccount, setHasAccount] = useState(props.hasAccount)
    //const [isLogged, setLogged] = useState(false)
    const [hasOrder, setHasOrder] = useState(false)
    const [signUpData, setSignUpData] = useState({
        gender: "",
        firstName: "",
        lastName: "",
        telephone: "",
        email: "",
        password: "",
        birthdate: someYearsAgo,
        passwordConfirm: "",
        acceptedData: false,
        acceptedTerms: false,
        acceptedNews: false,
        preferredLanguage: ""
    })

    const [farmacyForm, setFarmacyForm] = useState({
        canton: "",
        pharmacy: "",
        cardHolder: "",
        cardNumber: "",
        pharmacyName: "",
        pharmacyAddress: "",
        pharmacyCity: "",
        pharmacyZipCode: ""
    })

    useEffect(() => {
        var lang = localStorage.getItem("language")
        changeLanguage(lang)
    }, [props.hasAccount])

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                // return isLogged ? <FirstStepLogged
                //     texts={txt.signup}
                //     nextStep={changeActiveStep}
                //     onLogout={handleLogout}
                //     setData={setSignUpData}
                //     firebase={props.firebase} /> :
                return (hasAccount ? <FirstStepLogin
                    hasNoAccount={() => handleHasAccount(false)}
                    firebase={props.firebase}
                    texts={txt.signup}
                    setData={setSignUpData}
                    nextStep={changeActiveStep}
                /> : <FirstStepSignUp
                    hasAccount={() => handleHasAccount(true)}
                    data={signUpData}
                    setData={setSignUpData}
                    firebase={props.firebase}
                    nextStep={changeActiveStep}
                    texts={txt.signup} />)
            case 1:
                return !hasOrder ? <SecondStepChooseFarmacy
                    firebase={props.firebase}
                    hasOrder={() => handleHasOrder(true)}
                    data={farmacyForm}
                    setData={setFarmacyForm}
                    nextStep={changeActiveStep}
                    texts={txt.order}
                /> : <SecondStepOrderSummary
                    firebase={props.firebase}
                    dataFarmacy={farmacyForm}
                    dataSignUp={signUpData}
                    hasNotOrder={() => handleHasOrder(false)}
                    texts={txt.order}
                    nextStep={changeActiveStep}
                    textsSignUp={txt.signup}
                />
            case 2: return <ThirdStep firebase={props.firebase} texts={txt.final} nextStep={changeActiveStep} />

            default:
                return "unknown stepIndex"
        }
    }

    const changeActiveStep = (step) => {
        setActiveStep(step);
    }

    const handleHasAccount = (has) => {
        setHasAccount(has)
    }

    const handleHasOrder = (has) => {
        setHasOrder(has)
    }

    // const handleLogout = () => {
    //     setLogged(false)
    // }

    const getStepTitle = () => {
        switch (activeStep) {
            case 0:
                return hasAccount ? txt.signup.Announce : txt.signup.labelCreateAccount
            case 1:
                return hasOrder ? txt.signup.OrderOverview : txt.signup.PharmacyElections;
            default:
                return txt.signup.Managed;
        }
    }

    const changeLanguage = (lang) => {
        localStorage.setItem("language", lang)
        switch (lang) {
            case 'en':
                signUpData['preferredLanguage'] = "english";
                setSignUpData(signUpData)
                setTxt(En.subscription)
                break
            case 'fr':
                signUpData['preferredLanguage'] = "french";
                setSignUpData(signUpData)
                setTxt(frCH.subscription)
                break
            default:
                signUpData['preferredLanguage'] = "german";
                setSignUpData(signUpData)
                setTxt(deDE.subscription)
                break
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.subscriptonLayout}>
                <div className={classes.subscriptionHeader}>
                    {getStepTitle()}
                </div>
                <Stepper activeStep={activeStep} connector={<StepConnector classes={{
                    root: classes.subscriptionStepConnector,
                    alternativeLabel: classes.subscriptionStepConnector,
                    line: classes.subscriptionStepConnectorLine
                }} />} className={classes.subscriptionStepper} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconProps={{
                                classes: {
                                    active: classes.subscriptionStepperItem,
                                    completed: classes.subscriptionStepperItem,
                                    root: classes.subscriptionStepperItemDisabled,
                                }

                            }}
                            ></StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Paper className={classes.subscriptionPaper}>
                    <div>{getStepContent(activeStep)}</div>
                </Paper>
                <div className={classes.subsciptionLanguageBox}>
                    <aside>
                        <ul className={classes.subsciptionLanguageBoxList}>
                            <li data="de" className={classes.subsciptionLanguageBoxList} onClick={() => changeLanguage("de")}>
                                <a>
                                    <img src="https://cdn.weglot.com/flags/circle/de.svg" width="24" height="24" /></a></li>
                            <li data="fr" className={classes.subsciptionLanguageBoxList} onClick={() => changeLanguage("fr")}>
                                <a>
                                    <img src="https://cdn.weglot.com/flags/circle/fr.svg" width="24" height="24" /></a></li>
                            <li data="en" className={classes.subsciptionLanguageBoxList} onClick={() => changeLanguage("en")}>
                                <a >
                                    <img src="https://cdn.weglot.com/flags/circle/gb.svg" width="24" height="24" /></a></li>
                        </ul>
                    </aside>
                </div>
            </div>
        </ThemeProvider>
    )
}

const FormSubscription = withFB(FormSubscriptionBase)

export default FormSubscription;