import React, { useState, useEffect } from "react"
import {
    TextField,
    Typography,
    withStyles,
    Grid,
    useTheme,
    ThemeProvider,
    MenuItem,
    CircularProgress
} from "@material-ui/core"
import "../../../components/globalStyles.css"
import useSharedClasses from "../FormStyles"
import * as S from '../../GlobalStyledComponents'
import insuranceCard from "../../../images/insurance_card.png"
import { isInsuranceCardValid } from "../../utils/validators"

export default function SecondStepChooseFarmacy(props) {
    const listOfCantons = [,
        {
            "key": "AG",
            "value": "Aarau"
        },
        {
            "key": "AI",
            "value": "Appenzell Innerrhoden"
        },
        {
            "key": "AR",
            "value": "Appen-Ausserrhoden"
        },
        {
            "key": "BS",
            "value": "Basel Stadt"
        },
        {
            "key": "BL",
            "value": "Basel Land"
        },
        {
            "key": "BE",
            "value": "Bern"
        },
        {
            "key": "FR",
            "value": "Fribourg"
        },
        {
            "key": "GE",
            "value": "Genève"
        },
        {
            "key": "GL",
            "value": "Glarus"
        },
        {
            "key": "GR",
            "value": "Graubünden"
        },
        {
            "key": "JU",
            "value": "Jura"
        },
        {
            "key": "LU",
            "value": "Luzern"
        },
        {
            "key": "NE",
            "value": "Neuchâtel"
        },
        {
            "key": "NW",
            "value": "Nidwalden"
        },
        {
            "key": "OW",
            "value": "Obwalden"
        },
        {
            "key": "SH",
            "value": "Schaffhausen"
        },
        {
            "key": "SZ",
            "value": "Schwyz"
        },
        {
            "key": "SO",
            "value": "Solothurn"
        },
        {
            "key": "SG",
            "value": "Sankt-Gallen"
        },
        {
            "key": "TG",
            "value": "Thurgau"
        },
        {
            "key": "TI",
            "value": "Ticino"
        },
        {
            "key": "UR",
            "value": "Uri"
        },
        {
            "key": "VS",
            "value": "Valais"
        },
        {
            "key": "VD",
            "value": "Vaud"
        },
        {
            "key": "ZG",
            "value": "Zoug"
        },
        {
            "key": "ZH",
            "value": "Zürich"
        }];

    const [data, setData] = useState(props.data)
    const [pharmacies, setPharmacities] = useState([])
    const [isFormValid, setIsFormValid] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [stateErrorMessage, setStateErrorMessage] = useState({
        canton: "",
        pharmacy: "",
        cardHolder: "",
        cardNumber: ""
    })
    const [error, setError] = useState()
    const classes = useSharedClasses()

    const onsubmit = () => {
        setLoading(true);
        const alreadyLoggedIn = props.firebase.isUserLoggedIn()
        if (alreadyLoggedIn) {
            props.firebase.doAddUserPharmacyData(props.data).then(() => {
                setLoading(false);
                sendWeebhook();
                props.hasOrder();
            })
        } else {
            props.nextStep(0);
        }
    }

    const validateInput = name => {
        if (data[name] === '') {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorValueNeeded,
            })
        } else if (name === 'cardNumber' && !isInsuranceCardValid(data.cardNumber)) {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorInvalidCardNumber,
            })
        } else {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: ""
            })
        }
    }

    const sendWeebhook = () => {
        var hookURL = "https://hook.eu1.make.com/kbc7e5j0x2c8lfri0sisrr6rtkpcoxw5";

        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", hookURL, false); // false for synchronous request
        xmlHttp.send(null);
    }

    const getErrorTexts = name => {
        if (data[name] === '') {
            return props.texts.errorValueNeeded
        } else if (name === 'cardNumber' && !isInsuranceCardValid(data.cardNumber) && stateErrorMessage['cardNumber'].length > 0) {
            return props.texts.errorInvalidCardNumber
        } else {
            return ""
        }
    }

    useEffect(() => {
        setIsFormValid(
            isInsuranceCardValid(data.cardNumber) &&
            data.cardHolder !== "" &&
            data.pharmacy !== "" &&
            data.canton !== ""
        )
        props.setData(data)
    }, [data]);

    useEffect(() => {
        if (props.data.canton != null) {
            getPharmacyList(props.data.canton);
        }
    });

    const getPharmacyList = async (canton) => {
        var d = await props.firebase.doGetPharmacyList(canton);
        setPharmacities(d);
    }

    const handleChange = e => {
        setStateErrorMessage({
            ...stateErrorMessage,
            [e.target.name]: ""
        })

        if (e.target.name === "canton") {
            getPharmacyList(e.target.value);
        }
        if (e.target.name === "pharmacy") {
            var element = pharmacies.find((item) => item['id'] == e.target.value)
            if (element) {
                setData({
                    ...data,
                    ['pharmacyName']: element['data']['name'],
                    ['pharmacyAddress']: element['data']['address'],
                    ['pharmacyCity']: element['data']['city'],
                    ['pharmacyZipCode']: element['data']['zipCode'],
                    [e.target.name]: e.target.value
                })
            }
        } else
            setData({ ...data, [e.target.name]: e.target.value })
    }

    const onGoBack = () => {
        props.nextStep(0);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.subscriptionSubtitle}>
                    {props.texts.title1}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    name="canton"
                    value={data.canton}
                    onChange={handleChange}
                    error={stateErrorMessage.canton !== "" ? true : false}
                    helperText={stateErrorMessage.canton.length > 0 ? getErrorTexts("canton") : ""}
                    variant="outlined"
                    label={props.texts.labelCanton}
                    InputProps={{
                        classes: {
                            root: classes.subscriptionInput,
                            notchedOutline: classes.subscriptionInputNotchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.subscriptionInputLabel,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                        },
                    }}
                    fullWidth
                    onBlur={() => validateInput("canton")} select>
                    {
                        listOfCantons.map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)
                    }
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.subscriptionSubtitle + " " + classes.subscriptionMargin45}>
                    {props.texts.title2}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    name="pharmacy"
                    value={data.pharmacy}
                    onChange={handleChange}
                    error={stateErrorMessage.pharmacy !== "" ? true : false}
                    helperText={stateErrorMessage.pharmacy.length > 0 ? getErrorTexts("pharmacy") : ""}
                    variant="outlined"
                    label={props.texts.labelPharmacy}
                    InputProps={{
                        classes: {
                            root: classes.subscriptionInput,
                            notchedOutline: classes.subscriptionInputNotchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.subscriptionInputLabel,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                        },
                    }}
                    fullWidth
                    disabled={data.canton === ""}
                    onBlur={() => validateInput("pharmacy")} select>
                    {
                        pharmacies.map((item) => <MenuItem key={item.id} value={item.id}>{item.data['name']}</MenuItem>)
                    }
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.subscriptionSubtitle + " " + classes.subscriptionMargin45}>
                    {props.texts.title3}
                </Typography>
            </Grid>

            <Typography className={classes.subscriptionSwichtText + " " + classes.removeMarginLeft}>
                {props.texts.labelDescriptionText}
            </Typography>

            <Grid item xs={12}>
                <div className={classes.pharmacyBox}>
                    <img src={insuranceCard} className={classes.pharmacyBoxCard} />
                </div>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    error={stateErrorMessage.cardHolder !== "" ? true : false}
                    variant="outlined"
                    name="cardHolder"
                    label={props.texts.labelCardHolder}
                    InputProps={{
                        classes: {
                            root: classes.subscriptionInput,
                            notchedOutline: classes.subscriptionInputNotchedOutline,
                        },

                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.subscriptionInputLabel,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                        },
                    }}
                    helperText={stateErrorMessage.cardHolder.length > 0 ? getErrorTexts("cardHolder") : ""}
                    fullWidth
                    onChange={handleChange}
                    value={data.cardHolder}
                    onBlur={() => validateInput("cardHolder")}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    error={stateErrorMessage.cardNumber !== "" ? true : false}
                    variant="outlined"
                    name="cardNumber"
                    label={props.texts.labelCardNumber}
                    InputProps={{
                        classes: {
                            root: classes.subscriptionInput,
                            notchedOutline: classes.subscriptionInputNotchedOutline,
                        },

                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.subscriptionInputLabel,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                        },
                    }}
                    helperText={stateErrorMessage.cardNumber.length > 0 ? getErrorTexts("cardNumber") : ""}
                    fullWidth
                    onChange={handleChange}
                    value={data.cardNumber}
                    onBlur={() => validateInput("cardNumber")}
                />
            </Grid>

            <Grid item xs={12} className={classes.buttonGroup}>

                <div className={classes.subscriptionButtons}>
                    <S.SBButtonPrimary
                        id="checkout-createaccount-btn"
                        variant="contained"
                        color="primary"
                        onClick={onsubmit}
                        className={!isFormValid ? classes.subscriptionButton : classes.subscriptionButtonActive}
                        disabled={!isFormValid}
                    >
                        {isLoading ? <CircularProgress classes={{ colorPrimary: classes.circularPrimaryColor }} color="primary" thickness={10} size={16} /> : props.texts.labelSubmit}
                    </S.SBButtonPrimary>
                    {error && (
                        <div>
                            <Typography
                                variant="body1"
                                className={classes.errorMessage}
                            >
                                {error}
                            </Typography>
                        </div>
                    )}
                    {/* <S.SBButtonPrimaryOutlined
                        id="back-button"
                        variant="contained"
                        color="primary"
                        onClick={onGoBack}
                    >
                        {props.texts.btnLoginBack}
                    </S.SBButtonPrimaryOutlined> */}
                </div>
            </Grid>
        </Grid>
    )
}