import React, { useState, useEffect } from "react"
import {
    TextField,
    Typography,
    MenuItem,
    Grid,
    FormControlLabel,
    Switch,
    CircularProgress,
    InputAdornment
} from "@material-ui/core"
import { isPasswordValid, isEmailValid, isPhoneValid } from "../../utils/validators"
import "../../../components/globalStyles.css"
import useSharedClasses from "../FormStyles"
import * as S from '../../GlobalStyledComponents'
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"

const FirstStepSignUp = (props) => {
    const today = new Date()
    const eighteenYearsAgo = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate(),
        today.getHours()
    )
    const minDate = new Date(1900, 0, 1)
    
    const classes = useSharedClasses()
    const [isLoading, setLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [error, setError] = useState("")
    const [stateErrorMessage, setStateErrorMessage] = useState({
        gender: "",
        firstName: "",
        lastName: "",
        telephone: "",
        email: "",
        password: "",
        passwordConfirm: "",
        acceptedData: "",
        acceptedTerms: "",
        acceptedNews: "",
        birthday: eighteenYearsAgo
    })

    const [data, setData] = useState(props.data)
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);

    useEffect(() => {
        setIsFormValid(
            isEmailValid(data.email) &&
            isPasswordValid(data.password) &&
            data.password === data.passwordConfirm &&
            data.gender !== "" &&
            data.firstName !== "" &&
            data.lastName !== "" &&
            isPhoneValid(data.telephone) &&
            data.acceptedData === true &&
            data.acceptedTerms === true &&
            data.birthdate <= eighteenYearsAgo && data.birthdate > minDate && isValidDate(data.birthdate)
        )
        props.setData(data)
    }, [data]);

    const validateInput = name => {
        if (data[name] === '') {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorValueNeeded,
            })
        } else if (name === 'email' && !isEmailValid(data.email)) {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorEmail,
            })
        } else if (name === 'password' && !isPasswordValid(data.password)) {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorPasswordInvalid,
            })
        } else if (name === 'passwordConfirm' && data.passwordConfirm !== data.password) {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorPasswordMissmatch,
            })

        } else if (name === 'telephone' && !isPhoneValid(data.telephone)) {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorPhoneInvalid,
            })
        } else if (name === 'birthday' && !(data.birthdate < eighteenYearsAgo && data.birthdate > minDate && isValidDate(data.birthdate))) {
            setStateErrorMessage({
                ...stateErrorMessage,
                birthday: props.texts.errorValidDate,
            })
        } else {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: ""
            })
        }
    }

    const getErrorTexts = name => {
        if (data[name] === '') {
            return props.texts.errorValueNeeded
        } else if (name === 'email' && !isEmailValid(data.email)) {
            return props.texts.errorEmail
        } else if (name === 'password' && !isPasswordValid(data.password)) {
            return props.texts.errorPasswordInvalid
        } else if (name === 'passwordConfirm' && data.passwordConfirm !== data.password) {
            return props.texts.errorPasswordMissmatch
        } else if (name === 'telephone' && !isPhoneValid(data.telephone)) {
            return props.texts.errorPhoneInvalid
        } else if (name === 'birthday') {
            return props.texts.errorValidDate;
        } else {
            return ""
        }
    }

    function isValidDate(dateObject) {
        return new Date(dateObject).toString() !== 'Invalid Date';
    }

    const handleDateChange = e => {
        setData({ ...data, birthdate: e })
    }

    const handleChange = e => {
        setStateErrorMessage({
            ...stateErrorMessage,
            [e.target.name]: ""
        })
        switch (e.target.name) {
            case "acceptedData":
                setData({ ...data, [e.target.name]: e.target.checked })
                setStateErrorMessage({
                    ...stateErrorMessage,
                    ["acceptedData"]: e.target.checked ? "" : props.texts.errorPermissionRequired,
                })
                break
            case "acceptedTerms":
                setData({ ...data, [e.target.name]: e.target.checked })
                setStateErrorMessage({
                    ...stateErrorMessage,
                    ["acceptedTerms"]: e.target.checked ? "" : props.texts.errorPermissionRequired,
                })

                break
            case "acceptedNews":
                setData({ ...data, [e.target.name]: e.target.checked })

                break
            default:
                setData({ ...data, [e.target.name]: e.target.value })
                break
        }
    }

    const onSubmit = e => {
        setLoading(true);
        const alreadyLoggedIn = props.firebase.isUserLoggedIn()
        if (alreadyLoggedIn) {
            props.firebase.doSignOut().then(() => {
                signUp();
            })
        } else {
            signUp();
        }
    }

    const signUp = () => {
        const today = new Date()
        props.firebase
            .doCreateUserWithEmailAndPassword(data.email, data.password)
            .then(authUser => {
                props.firebase.user(authUser.user.uid).set({
                    email: data.email,
                    role: "user",
                    status: "testPeriod",
                    confirmDataProcessing: data.acceptedData,
                    confirmTermsOfUse: data.acceptedTerms,
                    confirmNews: data.acceptedNews,
                    gender: data.gender,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    country: "Schweiz",
                    countryCode: "ch",
                    phone: data.telephone,
                    preferredLanguage: data.preferredLanguage,
                    birthday: data.birthdate
                })
                props.firebase.sales(authUser.user.uid).set({
                    AccountCreationLocation: "web",
                    AccountCreatedDate: today,
                })
            }).then(() => {
                props.nextStep(1)
                setLoading(false)
            })
            .catch(err => {
                if (err.code == 'auth/email-already-in-use' || err.code == 'auth/account-exists-with-different-credential') {
                    setError(props.texts.errorEmailExists)
                } else {
                    setError(props.texts.errorUnkown)
                }
                setLoading(false)
            })
    }

    const togglePasswordHide = () => {
        setTogglePassword(!togglePassword)
    }

    const toggleConfirmPasswordHide = () => {
        setToggleConfirmPassword(!toggleConfirmPassword)
    }

    const showError = (error) => {
        return (<Typography
            variant="body1"
            className={classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch}
        >
            {error}
        </Typography>);
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h1" gutterBottom className={classes.subscriptionSubtitle}>
                        {props.texts.title}
                    </Typography>
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        required
                        id="select-gender-select"
                        name="gender"
                        value={data.gender}
                        onChange={handleChange}
                        error={stateErrorMessage.gender !== "" ? true : false}
                        helperText={stateErrorMessage.gender.length > 0 ? getErrorTexts("gender") : ""}
                        variant="outlined"
                        label={props.texts.labelTitle}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        fullWidth
                        onBlur={() => validateInput("gender")} select>
                        <MenuItem value="male">{props.texts.optionMale}</MenuItem>
                        <MenuItem value="female">{props.texts.optionFemale}</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={stateErrorMessage.firstName !== "" ? true : false}
                        variant="outlined"
                        name="firstName"
                        label={props.texts.labelFirstName}
                        helperText={stateErrorMessage.firstName.length > 0 ? getErrorTexts("firstName") : ""}
                        fullWidth
                        onChange={handleChange}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        value={data.firstName}
                        onBlur={() => validateInput("firstName")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={stateErrorMessage.lastName !== "" ? true : false}
                        variant="outlined"
                        name="lastName"
                        label={props.texts.labelLastName}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },

                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        helperText={stateErrorMessage.lastName.length > 0 ? getErrorTexts("lastName") : ""}
                        fullWidth
                        onChange={handleChange}
                        value={data.lastName}
                        onBlur={() => validateInput("lastName")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={stateErrorMessage.telephone !== "" ? true : false}
                        variant="outlined"
                        name="telephone"
                        label={props.texts.labelTelephone}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },

                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        helperText={stateErrorMessage.telephone.length > 0 ? getErrorTexts("telephone") : ""}
                        fullWidth
                        onChange={handleChange}
                        value={data.telephone}
                        onBlur={() => validateInput("telephone")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <KeyboardDatePicker
                        value={data.birthdate ?? ""}
                        onChange={e => handleDateChange(e)}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },

                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        maxDate={eighteenYearsAgo}
                        format="dd/MM/yyyy"
                        variant="inline"
                        inputVariant="outlined"
                        helperText={stateErrorMessage.birthday?.length > 0 ? getErrorTexts("birthday") : ""}
                        error={stateErrorMessage.birthday !== "" ? true : false}
                        autoOk
                        fullWidth
                        onBlur={() => validateInput("birthday")}
                        label={props.texts.labelBirthdate}
                        name="birthday"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={stateErrorMessage.email !== "" ? true : false}
                        variant="outlined"
                        name="email"
                        label={props.texts.labelEmail}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },

                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        helperText={stateErrorMessage.email.length > 0 ? getErrorTexts("email") : ""}
                        fullWidth
                        onChange={handleChange}
                        value={data.email}
                        onBlur={() => validateInput("email")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={stateErrorMessage.password !== "" ? true : false}
                        variant="outlined"
                        name="password"
                        label={props.texts.labelPassword}
                        type={togglePassword ? "text" : "password"}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },
                            endAdornment: (
                                <InputAdornment position="end"> {
                                    togglePassword ? (
                                        <Visibility className={classes.subscriptionSwitchInactive}
                                            onClick={togglePasswordHide}
                                        />
                                    ) : (
                                        <VisibilityOff className={classes.subscriptionSwitchInactive} onClick={togglePasswordHide}
                                        />
                                    )
                                }
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        helperText={stateErrorMessage.password.length > 0 ? getErrorTexts("password") : ""}
                        fullWidth
                        onChange={handleChange}
                        value={data.password}
                        onBlur={() => validateInput("password")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={stateErrorMessage.password !== "" ? true : false}
                        variant="outlined"
                        name="passwordConfirm"
                        type={toggleConfirmPassword ? "text" : "password"}
                        label={props.texts.labelPasswordRepeat}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },
                            endAdornment: (
                                <InputAdornment position="end"> {
                                    toggleConfirmPassword ? (
                                        <Visibility className={classes.subscriptionSwitchInactive}
                                            onClick={toggleConfirmPasswordHide}
                                        />
                                    ) : (
                                        <VisibilityOff className={classes.subscriptionSwitchInactive} onClick={toggleConfirmPasswordHide}
                                        />
                                    )
                                }
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        helperText={stateErrorMessage.passwordConfirm.length > 0 ? getErrorTexts("passwordConfirm") : ""}
                        fullWidth
                        onChange={handleChange}
                        value={data.passwordConfirm}
                        onBlur={() => validateInput("passwordConfirm")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.subscriptionSwichtTextForm}
                        control={
                            <Switch
                                name="acceptedData"
                                value={data.acceptedData}
                                onChange={handleChange}
                                required
                                color="primary"
                                classes={{
                                    thumb: data.acceptedData ? classes.subscriptionSwitchActive : classes.subscriptionSwitchInactive,
                                    track: data.acceptedData ? classes.subscriptionSwitchTrackActive : classes.subscriptionSwitchTrack,
                                }}

                            />
                        }
                        label={
                            <div className={classes.subscriptionSwichtText}>
                                {props.texts.labelAcceptData}
                            </div>
                        }
                    />
                    {stateErrorMessage.acceptedData !== "" && showError(stateErrorMessage.acceptedData)}
                    <FormControlLabel
                        className={classes.subscriptionSwichtTextForm}
                        control={
                            <Switch
                                name="acceptedTerms"
                                value={data.acceptedTerms}
                                onChange={handleChange}
                                required
                                color="primary"
                                classes={{
                                    thumb: data.acceptedTerms ? classes.subscriptionSwitchActive : classes.subscriptionSwitchInactive,
                                    track: data.acceptedTerms ? classes.subscriptionSwitchTrackActive : classes.subscriptionSwitchTrack,
                                }}
                            />
                        }
                        label={
                            <div className={classes.subscriptionSwichtText}>
                                {props.texts.labelAcceptTerms}
                            </div>
                        }
                    />
                    {stateErrorMessage.acceptedTerms !== "" && showError(stateErrorMessage.acceptedTerms)}
                    <FormControlLabel
                        className={classes.subscriptionSwichtTextForm}
                        control={
                            <Switch
                                name="acceptedNews"
                                value={data.acceptedNews}
                                onChange={handleChange}
                                color="primary"
                                classes={{
                                    thumb: data.acceptedNews ? classes.subscriptionSwitchActive : classes.subscriptionSwitchInactive,
                                    track: data.acceptedNews ? classes.subscriptionSwitchTrackActive : classes.subscriptionSwitchTrack,
                                }}
                            />
                        }
                        label={<div className={classes.subscriptionSwichtText}>{props.texts.labelAcceptNews}</div>}
                    />
                </Grid>
                <Grid item xs={12} className={classes.buttonGroup}>
                    <div className={classes.subscriptionButtons}>
                        <S.SBButtonPrimary
                            id="checkout-createaccount-btn"
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            className={!isFormValid ? classes.subscriptionButton : classes.subscriptionButtonActive}
                            disabled={!isFormValid}
                        >
                            {isLoading ? <CircularProgress classes={{ colorPrimary: classes.circularPrimaryColor }} color="primary" thickness={10} size={16} /> : props.texts.btnCreateAccountTxt}
                        </S.SBButtonPrimary>
                        {error && showError(error)}
                        <div className={classes.subscriptionSwichtText + " " + classes.subscriptionSwichtTextCenter}>
                            {props.texts.labelHasAccount}{" "}
                            <S.LinkButton onClick={props.hasAccount}>
                                {props.texts.labelHasAccountLink}
                            </S.LinkButton>
                        </div>
                    </div>

                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default FirstStepSignUp;