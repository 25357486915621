import React, { useState, useEffect } from "react"
import LayoutOB from "../components/layoutob"
import Seo from "../components/seo"
import { useLocation } from "@reach/router"
import { deDE } from "../lang/de-DE"
import { deCH } from "../lang/de-CH"
import FormSubscription from "../components/Form/subscription/FormSubscription"

export default function subscription() {
  const location = useLocation()
  var searchParams = new URLSearchParams(location.search)
  var countryParam = searchParams.get("country")
  const [country, setCountry] = useState(countryParam)


  return (
    <LayoutOB location={country} footerDisabled={true} path={location.pathname} language="de-CH">
      <Seo title="DoryGo - Subscription" description="Subscription" lang="de-CH" />
      <FormSubscription/>
    </LayoutOB>
  )
}