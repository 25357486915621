import React, { useState, useEffect } from "react"
import {
    TextField,
    Typography,
    Grid,
    MenuItem,
    CircularProgress,
} from "@material-ui/core"
import "../../../components/globalStyles.css"
import useSharedClasses from "../FormStyles"
import * as S from '../../GlobalStyledComponents'

export default function ThirdStep(props) {
    const classes = useSharedClasses()

    const [data, setData] = useState(props.texts.option1);
    const [optionValue, setOptionValue] = useState(0);
    const [stateErrorMessage, setStateErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false)

    //const onSubmit = () => { }
    const onGoBack = () => {
        setLoading(true);
        const alreadyLoggedIn = props.firebase.isUserLoggedIn()
        if (alreadyLoggedIn) {
            props.firebase.doAddUserSurveyData(data).then(() => {
                setLoading(false);
                window.location.assign('https://dorygo.com');
            })
        }
    }
    const validateInput = () => {
        if (data.length < 2) {
            setStateErrorMessage("error message")
        } else {
            setStateErrorMessage("")
        }
    }

    const handleChange = e => {
        setStateErrorMessage("")
        setOptionValue(e.target.value)

        if (e.target.value == 5) {
            setData("");
        } else {
            const list = [props.texts.option1, props.texts.option2, props.texts.option3, props.texts.option4, props.texts.option5, props.texts.option6];

            setData(list[e.target.value]);
        }
    }

    const handleChangeInput = e => {
        setData(e.target.value);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.subscriptionSubtitle + " " + classes.subscriptionSwichtTextCenter}>
                    {props.texts.title}
                </Typography>
            </Grid>



            <Grid item xs={12} className={classes.buttonGroup}>
                <Grid item xs={12}>
                    <Typography className={classes.subscriptionSubtitle}>
                        {props.texts.title1}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={optionValue !=5? classes.marginBottom60: classes.finalTextMargin}>
                    <TextField
                        required
                        name="canton"
                        value={optionValue}
                        onChange={handleChange}
                        error={stateErrorMessage !== "" ? true : false}
                        helperText={""}
                        variant="outlined"
                        label={props.texts.surveyTitle}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        fullWidth
                        onBlur={() => validateInput()} select>
                        <MenuItem key={0} value={0}>{props.texts.option1}</MenuItem>
                        <MenuItem key={1} value={1}>{props.texts.option2}</MenuItem>
                        <MenuItem key={2} value={2}>{props.texts.option3}</MenuItem>
                        <MenuItem key={3} value={3}>{props.texts.option4}</MenuItem>
                        <MenuItem key={4} value={4}>{props.texts.option5}</MenuItem>
                        <MenuItem key={5} value={5}>{props.texts.option6}</MenuItem>

                    </TextField>
                </Grid>

                {
                    optionValue == 5 && (
                        <Grid item xs={12} className={classes.marginBottom60}>
                    <TextField
                        required
                        error={stateErrorMessage !== "" ? true : false}
                        variant="outlined"
                        name="cardHolder"
                        label={props.texts.question}
                        InputProps={{
                            classes: {
                                root: classes.subscriptionInput,
                                notchedOutline: classes.subscriptionInputNotchedOutline,
                            },

                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.subscriptionInputLabel,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                            },
                        }}
                        helperText={stateErrorMessage.length > 0 ? props.texts.error : ""}
                        fullWidth
                        onChange={handleChangeInput}
                        value={data}
                        onBlur={() => validateInput()}
                    />
                </Grid>
                    )
                }

                <div className={classes.subscriptionButtons}>
                    {/* <S.SBButtonPrimary
                        id="checkout-createaccount-btn"
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        className={classes.subscriptionButtonActive}
                    >
                        {props.texts.labelButton1}
                    </S.SBButtonPrimary> */}

                    <S.SBButtonPrimaryOutlined
                        id="back-button"
                        variant="contained"
                        color="primary"
                        onClick={onGoBack}
                    >
                         {isLoading ? <CircularProgress classes={{ colorPrimary: classes.subscriptionStepperItem }} color="primary" thickness={10} size={16} /> : props.texts.labelButton2}
                    </S.SBButtonPrimaryOutlined>
                </div>
            </Grid>
        </Grid>
    )
}