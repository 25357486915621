import React, { useState, useEffect } from "react"
import {
    Typography,
    Grid,
    useTheme,
    ThemeProvider,
    CircularProgress,
} from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import "../../../components/globalStyles.css"
import useSharedClasses from "../FormStyles"
import * as S from '../../GlobalStyledComponents'
import test from "../../../images/DoryHealth_Product_Mobilephone.jpg"
import { Check, CheckBox, CheckCircleRounded, CheckRounded, IndeterminateCheckBoxRounded } from "@material-ui/icons"

const SecondStepOrderSummary = (props) => {
    const classes = useSharedClasses()

    const [isLoading, setLoading] = useState(false)

    const onSubmit = () => {
        setLoading(true);
        const alreadyLoggedIn = props.firebase.isUserLoggedIn()
        if (alreadyLoggedIn) {
            props.firebase.doSetOnboardingComplete().then(() => {
                setLoading(false);
                props.nextStep(2);
            })
        } else {
            props.nextStep(0);
        }
    }

    return (
        <Grid container>
            <Grid xs={12} item>
                <Typography className={classes.subscriptionSubtitle}>
                    {props.texts.title4}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <div className={classes.orderDetailBox}>
                    <img src={test} className={classes.orderDetailBoxImage} />
                    <Grid className={classes.row}>
                        <CheckCircleRounded className={classes.orderDetailBoxCheckIcon} /> <div className={classes.subscriptionSwichtText + " " + classes.imageBoxTextColor}>{props.texts.text1}</div>
                    </Grid>
                    <Grid className={classes.row}>
                        <CheckCircleRounded className={classes.orderDetailBoxCheckIcon} /> <div className={classes.subscriptionSwichtText + " " + classes.imageBoxTextColor}>{props.texts.text2}</div>
                    </Grid>
                    <Grid className={classes.row}>
                        <CheckCircleRounded className={classes.orderDetailBoxCheckIcon} /><div className={classes.subscriptionSwichtText + " " + classes.imageBoxTextColor}>{props.texts.text3}</div>
                    </Grid>
                    <Typography className={classes.orderDetailBoxSubtitle}>{props.texts.text4}*</Typography>

                    <Grid className={classes.row}>
                        <div className={classes.subscriptionSwichtText + " " + classes.imageBoxTextColor + " " + classes.removeMarginLeft}>*{props.texts.text5} </div>
                    </Grid>
                </div>
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subscriptionSubtitle}>
                    {props.texts.title5}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subscriptionSwichtText + " " + classes.removeMarginLeft + " " + classes.marginBottom60}>
                    {props.textsSignUp.labelTitle}: {props.dataSignUp.gender}<br />
                    {props.textsSignUp.labelLastName}: {props.dataSignUp.lastName}<br />
                    {props.textsSignUp.labelFirstName}: {props.dataSignUp.firstName}<br />
                    {props.textsSignUp.labelEmail}: {props.dataSignUp.email}<br />
                    {props.textsSignUp.labelTelephone}: {props.dataSignUp.telephone}
                </Typography>
            </Grid>

            <Grid xs={12} item>
                <Typography className={classes.subscriptionSubtitle}>
                    {props.texts.title6}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subscriptionSwichtText + " " + classes.removeMarginLeft + " " + classes.marginBottom60}>
                    {props.dataFarmacy.pharmacyName != "" ? props.dataFarmacy.pharmacyName : ""}<br />
                    {props.dataFarmacy.pharmacyAddress != "" ? props.dataFarmacy.pharmacyAddress : ""}<br />
                    {props.dataFarmacy.pharmacyZipCode != "" ? props.dataFarmacy.pharmacyZipCode + " " : ""}
                    {props.dataFarmacy.pharmacyCity != "" ? props.dataFarmacy.pharmacyCity : ""}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subscriptionSubtitle}>
                    {props.texts.title3}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subscriptionSwichtText + " " + classes.removeMarginLeft + " " + classes.marginBottom60}>
                    {props.texts.labelInsurance}: {props.dataFarmacy.cardHolder}<br />
                    {props.texts.labelIdentificationNumber}: {props.dataFarmacy.cardNumber}
                </Typography>
            </Grid>

            <Grid xs={12} item className={classes.buttonGroup}>
                <div className={classes.subscriptionButtons}>
                    <S.SBButtonPrimary
                        id="checkout-createaccount-btn"
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        className={classes.subscriptionButtonActive}
                    >
                        {isLoading ? <CircularProgress classes={{ colorPrimary: classes.circularPrimaryColor }} color="primary" thickness={10} size={16} /> : props.texts.labelOrderBtn}
                    </S.SBButtonPrimary>
                    <S.SBButtonPrimaryOutlined
                        id="back-button"
                        variant="contained"
                        color="primary"
                        onClick={props.hasNotOrder}
                    >
                        {props.texts.btnLoginBack}
                    </S.SBButtonPrimaryOutlined>
                </div>
            </Grid>
        </Grid>
    )
}

export default SecondStepOrderSummary;