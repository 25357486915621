import React, { useState, useEffect } from "react"
import {
    TextField,
    Typography,
    Grid,
    CircularProgress,
    InputAdornment
} from "@material-ui/core"
import "../../../components/globalStyles.css"
import useSharedClasses from "../FormStyles"
import * as S from '../../GlobalStyledComponents'
import { isEmailValid, isPasswordValid } from "../../utils/validators"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const FirstStepLogin = (props) => {
    const classes = useSharedClasses()
    const [isFormValid, setIsFormValid] = useState(false)
    const [stateErrorMessage, setStateErrorMessage] = useState({
        email: "",
        password: "",
    })
    const [error, setError] = useState()
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: ""
    })

    const [togglePassword, setTogglePassword] = useState(false)

    const onSubmit = () => {
        setLoading(true);
        const alreadyLoggedIn = props.firebase.isUserLoggedIn()
        if (!alreadyLoggedIn) {
            signIn()
        } else {
            signIn()
        }
    }

    const togglePasswordHide = () => {
        setTogglePassword(!togglePassword)
    }

    const signIn = () => {
        props.firebase
            .doSignInWithEmailAndPassword(data.email, data.password)
            .then(async () => {
                var data = await props.firebase.doGetUserData()
                props.setData({
                    gender: data['gender'],
                    firstName: data['firstName'],
                    lastName: data['lastName'],
                    telephone: data['phone'],
                    email: data['email'],
                    acceptedData: data['confirmDataProcessing'],
                    acceptedTerms: data['confirmTermsOfUse'],
                    acceptedNews: data['confirmNews'],
                    preferredLanguage: data['preferredLanguage']
                });

                props.nextStep(1)
                setLoading(false)
            })
            .catch(err => {
                // if (err.code === "auth/wrong-password") {
                //     setError(props.texts.errorWrongPassword)
                // } else if (err.code === "auth/too-many-requests") {
                //     setError(props.texts.errorTooManyRequests)
                // } else if (err.code === "auth/user-not-found") {
                //     setError(props.texts.errorWrongPassword)
                // } else {
                //     setError(props.errorUnknown)
                // }
                setError(err.code)
                setLoading(false)
            })
    }

    const getFirebaseErrors = (error) => {
        if (error === "auth/wrong-password") {
            return props.texts.errorWrongPassword
        } else if (error === "auth/too-many-requests") {
            return props.texts.errorTooManyRequests
        } else if (error === "auth/user-not-found") {
            return props.texts.errorWrongPassword
        } else {
            return props.errorUnknown
        }
    }

    const getErrorTexts = (name) => {
        if (data[name] === '') {
            return props.texts.errorValueNeeded
        } else if (name === 'email' && !isEmailValid(data.email)) {
            return props.texts.errorEmail
        } else if (name === 'password' && !isPasswordValid(data.password)) {
            return props.texts.errorPasswordInvalid
        } else {
            return ""
        }
    }

    const validateInput = name => {
        if (data[name] === '') {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorValueNeeded,
            })
        } else if (name === 'email' && !isEmailValid(data.email)) {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorEmail,
            })
        } else if (name === 'password' && !isPasswordValid(data.password)) {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: props.texts.errorPasswordInvalid,
            })
        } else {
            setStateErrorMessage({
                ...stateErrorMessage,
                [name]: ""
            })
        }
    }

    useEffect(() => setIsFormValid(
        isEmailValid(data.email) &&
        isPasswordValid(data.password)
    ), [data]);

    const handleChange = e => {
        setStateErrorMessage({
            ...stateErrorMessage,
            [e.target.name]: ""
        })
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const showError = (error) => {
        return (<Typography
            variant="body1"
            className={classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch}
        >
            {getFirebaseErrors(error)}
        </Typography>);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h1" gutterBottom className={classes.subscriptionSubtitle}>
                    {props.texts.titleLogin}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    error={stateErrorMessage.email !== "" ? true : false}
                    variant="outlined"
                    name="email"
                    label={props.texts.labelEmail}
                    InputProps={{
                        classes: {
                            root: classes.subscriptionInput,
                            notchedOutline: classes.subscriptionInputNotchedOutline,
                        },

                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.subscriptionInputLabel,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                        },
                    }}
                    helperText={stateErrorMessage.email.length > 0 ? getErrorTexts("email") : ""}
                    fullWidth
                    onChange={handleChange}
                    value={data.email}
                    onBlur={() => validateInput("email")}
                    autoComplete="email"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    error={stateErrorMessage.password !== "" ? true : false}
                    variant="outlined"
                    name="password"
                    type={togglePassword ? "text" : "password"}
                    label={props.texts.labelPassword}
                    InputProps={{
                        classes: {
                            root: classes.subscriptionInput,
                            notchedOutline: classes.subscriptionInputNotchedOutline,
                        },
                        endAdornment: (
                            <InputAdornment position="end"> {
                                togglePassword ? (
                                    <Visibility className={classes.subscriptionSwitchInactive}
                                        onClick={togglePasswordHide}
                                    />
                                ) : (
                                    <VisibilityOff className={classes.subscriptionSwitchInactive} onClick={togglePasswordHide}
                                    />
                                )
                            }
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.subscriptionInputLabel,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            contained: classes.errorMessage + " " + classes.removeMarginLeft + " " + classes.finalSwitch
                        },
                    }}
                    helperText={stateErrorMessage.password !== "" ? getErrorTexts("password") : ""}
                    fullWidth
                    onChange={handleChange}
                    value={data.password}
                    onBlur={() => validateInput("password")}
                    autoComplete="password"
                />
            </Grid>

            <Grid item xs={12} className={classes.buttonGroup}>
                <div className={classes.subscriptionSwichtText + " " + classes.subscriptionLoginTextCenter}>
                    <S.LinkButton onClick={props.hasAccount}>
                        {props.texts.labelForgetPassword}
                    </S.LinkButton>
                </div>
                <div className={classes.subscriptionButtons}>
                    <S.SBButtonPrimary
                        onClick={onSubmit}
                        className={!isFormValid ? classes.subscriptionButton : classes.subscriptionButtonActive}
                        disabled={!isFormValid}
                    >
                        {isLoading ? <CircularProgress classes={{ colorPrimary: classes.circularPrimaryColor }} color="primary" thickness={10} size={16} /> : props.texts.btnLoginTxt}
                    </S.SBButtonPrimary>
                    {error && showError(error)}
                    <S.SBButtonPrimaryOutlined
                        id="back-button"
                        variant="contained"
                        color="primary"
                        onClick={props.hasNoAccount}
                    >
                        {props.texts.btnLoginBack}
                    </S.SBButtonPrimaryOutlined>
                </div>
            </Grid>
        </Grid>
    )
}

export default FirstStepLogin;